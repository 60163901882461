

// /* SCSS HEX */
// $royal-blue-dark: #002f6a;
// $mantis: #80d260;
// $sky-blue: #6acdf0;
// $pink-lace: #f9cff2;
// $languid-lavender: #dad4ef;
// $red: #f2161b;


/* SCSS RGB */
$royal-blue-dark: #002f6a;
$mantis: rgba(128, 210, 96, 1);
$sky-blue: rgba(106, 205, 240, 1);
$pink-lace: rgba(249, 207, 242, 1);
$languid-lavender: rgba(218, 212, 239, 1);
$logo-red: #f2161b;
$link-color: #1b62e2;
$light-blue-color: #bbd3f1;
$new-yellow-color: #FFDE59;

/* SCSS Gradient */
$gradient-top: linear-gradient(0deg, #002f6aff, #80d260ff, #6acdf0ff, #f9cff2ff, #dad4efff);
$gradient-right: linear-gradient(90deg, #002f6aff, #80d260ff, #6acdf0ff, #f9cff2ff, #dad4efff);
$gradient-bottom: linear-gradient(180deg, #002f6aff, #80d260ff, #6acdf0ff, #f9cff2ff, #dad4efff);
$gradient-left: linear-gradient(270deg, #002f6aff, #80d260ff, #6acdf0ff, #f9cff2ff, #dad4efff);
$gradient-top-right: linear-gradient(45deg, #002f6aff, #80d260ff, #6acdf0ff, #f9cff2ff, #dad4efff);
$gradient-bottom-right: linear-gradient(135deg, #002f6aff, #80d260ff, #6acdf0ff, #f9cff2ff, #dad4efff);
$gradient-top-left: linear-gradient(225deg, #002f6aff, #80d260ff, #6acdf0ff, #f9cff2ff, #dad4efff);
$gradient-bottom-left: linear-gradient(315deg, #002f6aff, #80d260ff, #6acdf0ff, #f9cff2ff, #dad4efff);
$gradient-radial: radial-gradient(#002f6aff, #80d260ff, #6acdf0ff, #f9cff2ff, #dad4efff);

$track-color: #eceff1 !default;
$thumb-color: #002F6A !default;

$thumb-radius: 12px !default;
$thumb-height: 24px !default;
$thumb-width: 35px !default;
$thumb-shadow-size: 4px !default;
$thumb-shadow-blur: 4px !default;
$thumb-shadow-color: rgba(0, 0, 0, .2) !default;
$thumb-border-width: 2px !default;
$thumb-border-color: #eceff1 !default;

$track-width: 100% !default;
$track-height: 8px !default;
$track-shadow-size: 1px !default;
$track-shadow-blur: 1px !default;
$track-shadow-color: rgba(0, 0, 0, .2) !default;
$track-border-width: 2px !default;
$track-border-color: #cfd8dc !default;

$track-radius: 5px !default;
$contrast: 5% !default;

$ie-bottom-track-color: darken($track-color, $contrast) !default;

@mixin shadow($shadow-size, $shadow-blur, $shadow-color) {
  box-shadow: $shadow-size $shadow-size $shadow-blur $shadow-color, 0 0 $shadow-size lighten($shadow-color, 5%);
}

@mixin track {
  cursor: pointer;
  height: $track-height;
  transition: all .2s ease;
  width: $track-width;
}

@mixin thumb {
  @include shadow($thumb-shadow-size, $thumb-shadow-blur, $thumb-shadow-color);
  background: $thumb-color;
  border: $thumb-border-width solid $thumb-border-color;
  border-radius: $thumb-radius;
  box-sizing: border-box;
  cursor: pointer;
  height: $thumb-height;
  width: $thumb-width;
}
@mixin customRange() {    
  &[type='range'] {
    -webkit-appearance: none;
    background: transparent;
    margin: $thumb-height * 0.5 0;
    width: $track-width;  
    &::-moz-focus-outer {
      border: 0;
    }  
    &:focus {
      outline: 0;  
      &::-webkit-slider-runnable-track {
        background: lighten($track-color, $contrast);
      }  
      &::-ms-fill-lower {
        background: $track-color;
      }  
      &::-ms-fill-upper {
        background: lighten($track-color, $contrast);
      }
    }  
    &::-webkit-slider-runnable-track {
      @include track;
      @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
      background: $track-color;
      border: $track-border-width solid $track-border-color;
      border-radius: $track-radius;
    }  
    &::-webkit-slider-thumb {
      @include thumb;
      -webkit-appearance: none;
      margin-top: ((-$track-border-width * 2 + $track-height) * 0.5 - $thumb-height * 0.5);
    }  
    &::-moz-range-track {
      @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
      @include track;
      background: $track-color;
      border: $track-border-width solid $track-border-color;
      border-radius: $track-radius;
      height: $track-height * 0.5;
    }  
    &::-moz-range-thumb {
      @include thumb;
    }  
    &::-ms-track {
      @include track;
      background: transparent;
      border-color: transparent;
      border-width: ($thumb-height * 0.5) 0;
      color: transparent;
    }  
    &::-ms-fill-lower {
      @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
      background: $ie-bottom-track-color;
      border: $track-border-width solid $track-border-color;
      border-radius: ($track-radius * 2);
    }  
    &::-ms-fill-upper {
      @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
      background: $track-color;
      border: $track-border-width solid $track-border-color;
      border-radius: ($track-radius * 2);
    }  
    &::-ms-thumb {
      @include thumb;
      margin-top: $track-height * 0.25;
    }  
    &:disabled {
      &::-webkit-slider-thumb,
      &::-moz-range-thumb,
      &::-ms-thumb,
      &::-webkit-slider-runnable-track,
      &::-ms-fill-lower,
      &::-ms-fill-upper {
        cursor: not-allowed;
      }
    }
  }
}
@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}  
}
// @import "./fontawesome/scss/fontawesome.scss";
@font-face{
  font-family:"Font Awesome 5 Pro";
  font-display: swap;
  src:url('./fontawesome/webfonts/fa-solid-900.woff2');
  font-weight: 900;
}
@mixin fontAwesome(){
  // content: $code;
  // font-display: swap;
  display: inline-block;
  // font: normal normal normal 14px/1 FontAwesome;
  font-family: "Font Awesome 5 Pro", sans-serif;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$font_regular: 400;
$font_medium: 500;
// $font_medium: 700;
$font_bold: 700;
$alt_Font_Color: #b1b1b1;
$menu_height: 65px;

@mixin gradFromLeft($to) {
  background: $to;
  background: linear-gradient(90deg, darken($to, 30%) 0%, $to 40%, lighten($to, 10%) 100%);
}